import './App.css'
import Main from './pages/Main'

function App() {
  return (
    <>
      <Main></Main>
    </>
  )
}

export default App
