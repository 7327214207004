import { TbMessageCircle2 } from 'react-icons/tb'
const Card = ({ from, message, dest }) => {
  function getRandomDate() {
    const maxDate = Date.now()
    const timestamp = Math.floor(Math.random() * maxDate)
    return new Date(timestamp)
  }
  const today = getRandomDate()
  let hours = today.getHours()
  if (hours > 12) hours = hours - 12
  console.log(hours)

  const time = String(hours) + ':' + String(today.getMinutes()).padStart(2, '0')

  const day = today.toLocaleDateString('en-US', { weekday: 'short' })

  return (
    <div class="dialog-box">
      {/* <a href={dest} target="_new"> */}
      <div class="background-blur"></div>
      {/* </a> */}
      <div class="header">
        <div class="background-blur"></div>
        <div class="contents">
          <div class="left inline-flex align-middle">
            <TbMessageCircle2 size={'1.5em'} />
            <i className="">{from}</i>
          </div>
          <div class="right">
            {day} {time} pm
          </div>
        </div>
      </div>
      <div class="main-content leading-none text-black text-xs">{message}</div>
    </div>
  )
}

Card.defaultProps = {
  from: '',
  message: '',
  dest: '',
}
export default Card

// const Cardx = ({ img, title, content, badge }) => {
//   return (
//     <div class="card w-auto h-48 bg-animated shadow-xl mb-3">
//       <figure className="h-8">
//         <img src={img} alt="" />
//       </figure>
//       <div class="card-body h-auto">
//         <h2 class="card-title">{title}</h2>
//         <p>{content}</p>
//         <div class="card-actions justify-end">
//           <div class="badge badge-outline">{badge}</div>
//         </div>
//       </div>
//     </div>
//   )
// }
