import Window from '../components/Window'

const Main = () => {
  return (
    <div className="flex self-center">
      <Window />
      <div className="absolute bottom-0 left-0 text-2xs text-white">
        Created by Samuel Davidson
      </div>
    </div>
  )
}
export default Main
