import Card from './Card'
// import laptop from '../assets/laptop.png'

const Content = () => {
  return (
    <div className="mx-2 p-0 text-white">
      <Card
        from="Samuel Davidson"
        message="I'm a software developer with a BS in Software Systems Design. Primarily interested in web applications, machine learning, and cloud services."
        dest="/about"
      ></Card>
      <Card
        from="Frameworks"
        message="I have experience with React, React Native, Express, Electron, P5, Keras, TensorFlow, Unity, Android, and more.."
      ></Card>
      <Card
        from="Languages"
        message="Experienced with C++, C#, Java, Python, JavaScript, MangoDB, and SQL."
      ></Card>
    </div>
  )
}
export default Content
